<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">영업일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    v-model="searchDateInputDateRange"
                    type="lookupDetail-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">영업구분</div>
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                    cssClass="lookup-condition-dropdown"
                    v-model="searchOptions.roomBsnCode"
                    :dataSource="searchOptions.roomBsnCodeOptions"
                    :fields="commonCodeFields"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">객실타입</div>
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                    cssClass="lookup-condition-dropdown"
                    v-model="searchOptions.roomType"
                    :dataSource="searchOptions.roomTypeOptions"
                    :fields="commonCodeFields"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">가용객실 목록</div>
              <div class="header-caption">[{{ availableRoomsCount | numberWithCommas }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      :is-key-color="true"
                      v-on:click.native="onSaveButtonClicked"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="createAvailableRoomButtonClicked"
                  >
                    가용 객실 생성
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelButtonClicked"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                      v-model="isModifyMode"
                      :checked="isModifyMode"
                      @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
                ref="createAvailableRoomGrid"
                v-bind="createAvailableRoomProps"
                :dataSource="availableRooms"
                :allowFiltering="!isModifyMode"
                :allowSorting="!isModifyMode"
                :is-auto-select-cell="!isModifyMode"
                @headerCellInfo="onGridHeaderCellInfo"
                @queryCellInfo="onGridQueryCellInfo"
                @cellSelected="onGridCellSelected"
                @cellSaved="onGridCellSaved"
                @actionBegin="onGridActionBegin"
                @actionComplete="onGridActionComplete"
            />
          </div>
        </section>
      </article>
    </div>
    <create-available-room-popup
      v-if="isCreateAvailableRoomPopupOpen"
      ref="createAvailableRoomPopup"
      @popupClosed="createAvailableRoomPopupClosed"
    />
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body{overflow:hidden;border:none}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import InputDateRange from "@/components/common/datetime/InputDateRange";

import CreateAvailableRoomPopup from "../popup/CreateAvailableRoomPopup";
import EditMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Edit, ExcelExport, ForeignKey, Resize, Selection, Page} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode, commonCodesGetSortNo,
} from '@/utils/commonCodes';
import {getFormattedDate} from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import moment from "moment";
import { orderBy as _orderBy } from "lodash";

export default {
  name : "CreateAvailableRoom",
  components: {
    EjsGridWrapper,
    InputDateRange,
    CreateAvailableRoomPopup,
    EditMultipleColumnsPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas
  },
  data() {
    return {
      isCreateAvailableRoomPopupOpen:false,
      isEditMultipleColumnsPopupOpen: false,
      commonCodeFields: { text: "comName", value: "comCode" },
      searchOptions: {
        bsnDateFrom: null,
        bsnDateTo: null,
        roomBsnCode: null,
        roomType: null,
        roomBsnCodeOptions: [],
        roomTypeOptions: [],
      },
      orgAvailableRooms: [],
      availableRooms: [],
      availableRoomsCount: 0,
      isModifyMode: false,
    };
  },
  created() {
    this.searchOptions.roomBsnCodeOptions = commonCodesGetCommonCode(
        "ROOM_BSN_CODE",
        true
    );

    if (
        this.searchOptions.roomBsnCodeOptions.findIndex(
            (obj) => obj.comCode === "ALL"
        ) === -1
    ) {
      this.searchOptions.roomBsnCodeOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.roomTypeOptions = commonCodesGetCommonCode("ROOM_TYPE", true);

    if (this.searchOptions.roomTypeOptions.findIndex((obj) => obj.comCode === "ALL") === -1) {
      this.searchOptions.roomTypeOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.roomBsnCode = "ALL";
    this.searchOptions.roomType = "ALL";

    this.searchOptions.bsnDateFrom = getFormattedDate(moment().format());
    this.searchOptions.bsnDateTo = getFormattedDate(moment().set('month',Number(moment().format("M"))+1).format());
  },
  destroyed() {},
  computed: {
    isPopupOpened() {
      return (
        this.isCreateAvailableRoomPopupOpen ||
        this.isEditMultipleColumnsPopupOpen
      );
    },
    searchDateInputDateRange: {
      get: function () {
        return {
          from: this.searchOptions.bsnDateFrom,
          to: this.searchOptions.bsnDateTo
        };
      },
      set: function (dateRange) {
        this.searchOptions.bsnDateFrom = dateRange.from;
        this.searchOptions.bsnDateTo = dateRange.to;
      }
    },
    createAvailableRoomProps() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport, Page],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        allowPaging: true,
        allowExcelExport: true,
        pageSettings: { pageSize: 50 },
        columns: [
          {
            field: "bsnDate",
            headerText: "일자",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "dwCode",
            headerText: "요일",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "DW_CODE",
            allowEditing: false,
          },
          {
            field: "roomBsnCode",
            headerText: "영업구분",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_BSN_CODE",
            allowEditing: false,
          },
          {
            field: "roomType",
            headerText: "객실타입",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_TYPE",
            allowEditing: false,
          },
          {
            headerText: "① 가용",
            columns: [
              {
                field: "usefulRoomCnt",
                headerText: "전체",
                type: "number",
                isNumericType: true,
                inputNumberProperty: { maxLength: 4 },
                multiEdit: {
                  min: 1,
                  max: 9999,
                  propMaxLength: 4,
                  allowDot: false,
                  allowMinus: false,
                  displayComma: false,
                },
                minWidth: 16,
                width: 80,
                textAlign: "right",
                allowEditing: true,
              },
              {
                field: "blockRoomCnt",
                headerText: "블럭",
                type: "number",
                isNumericType: true,
                inputNumberProperty: { maxLength: 4 },
                multiEdit: {
                  min: 1,
                  max: 9999,
                  propMaxLength: 4,
                  allowDot: false,
                  allowMinus: false,
                  displayComma: false,
                },
                minWidth: 16,
                width: 80,
                textAlign: "right",
                allowEditing: true,
              },
              {
                field: "webOpenRoomCnt",
                headerText: "웹오픈",
                type: "number",
                isNumericType: true,
                inputNumberProperty: { maxLength: 4 },
                multiEdit: {
                  min: 1,
                  max: 9999,
                  propMaxLength: 4,
                  allowDot: false,
                  allowMinus: false,
                  displayComma: false,
                },
                minWidth: 16,
                width: 80,
                textAlign: "right",
                allowEditing: true,
              },
            ]
          },
          {
            headerText: "② 고장/(H/U)",
            columns: [
              {
                field: "outofRoomCnt",
                headerText: "고장",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 80,
                textAlign: "right",
                allowEditing: false,
              },
              {
                field: "houseRoomCnt",
                headerText: "(H/U)",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 80,
                textAlign: "right",
                allowEditing: false,
              },
            ]
          },
          {
            headerText: "③ 예약/투숙",
            columns: [
              {
                field: "resveRoomCnt",
                headerText: "예약",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 80,
                textAlign: "right",
                allowEditing: false,
              },
              {
                field: "stayRoomCnt",
                headerText: "투숙",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 80,
                textAlign: "right",
                allowEditing: false,
              },
            ]
          },
          {
            headerText: "①-②-③ 사용가능",
            columns: [
              {
                field: "usefulRoomCntAll",
                headerText: "전체",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 80,
                textAlign: "right",
                allowEditing: false,
              },
              {
                field: "webOpenRoomCntAll",
                headerText: "웹오픈",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 80,
                textAlign: "right",
                allowEditing: false,
              },
            ]
          },
          {
            field: "webOpenDt",
            headerText: "웹오픈 일시",
            type: "string",
            minWidth: 16,
            width: 130,
            allowEditing: false,
          },
          {
            field: "webEndDt",
            headerText: "웹오픈 종료일시",
            type: "string",
            minWidth: 16,
            width: 130,
            allowEditing: false,
          },
          {
            field: "insertName",
            headerText: "등록자",
            type: "string",
            minWidth: 16,
            width: 90,
            allowEditing: false,
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            type: "string",
            minWidth: 16,
            width: 130,
            allowEditing: false,
          },
          {
            field: "updateName",
            headerText: "수정자",
            type: "string",
            minWidth: 16,
            width: 90,
            allowEditing: false,
          },
          {
            field: "updateDt",
            headerText: "수정일시",
            type: "string",
            minWidth: 16,
            width: 130,
            allowEditing: false,
          },
        ],
      };
    },
  },
  methods: {
    async onViewButtonClicked() {
      const data = await GolfErpAPI.getAvailableRooms({
        bsnDateFrom: this.searchOptions.bsnDateFrom,
        bsnDateTo: this.searchOptions.bsnDateTo,
        roomBsnCode: this.searchOptions.roomBsnCode === "ALL" ? null : this.searchOptions.roomBsnCode,
        roomType: this.searchOptions.roomType === "ALL" ? null : this.searchOptions.roomType,
      });

      let bsnDateBefore = null;
      this.availableRooms  = _orderBy(data.map(item => ({
        ...item,
        dwCode: item.roomCalender.dwCode,
        roomBsnCode: item.roomCalender.roomBsnCode,
        roomTypeSortNo: commonCodesGetSortNo("ROOM_TYPE", item.roomType),
        usefulRoomCntAll: item.usefulRoomCnt - item.blockRoomCnt - item.outofRoomCnt - item.houseRoomCnt - item.resveRoomCnt - item.stayRoomCnt,
        webOpenRoomCntAll:
          // 웹오픈(사용가능) : 웹오픈(사용가능)보다 전체(사용가능)이 많으면 웹오픈(사용가능), 아니면 전체(사용가능) 데이터.
          (item.usefulRoomCnt - item.blockRoomCnt - item.outofRoomCnt - item.houseRoomCnt - item.resveRoomCnt - item.stayRoomCnt) >= (item.webOpenRoomCnt - item.resveChannelWebRoomCnt) ?
            (item.webOpenRoomCnt - item.resveChannelWebRoomCnt) :
            (item.usefulRoomCnt - item.blockRoomCnt - item.outofRoomCnt - item.houseRoomCnt - item.resveRoomCnt - item.stayRoomCnt),
        webOpenDt: item.roomCalender.webOpenDt,
        webEndDt: item.roomCalender.webEndDt,
      })), ["bsnDate", "roomTypeSortNo"]).map(item => {
        const isTopLine = bsnDateBefore && bsnDateBefore !== item.bsnDate;
        const opcityData = (!bsnDateBefore || bsnDateBefore !== item.bsnDate);
        bsnDateBefore = JSON.parse(JSON.stringify(item.bsnDate));
        return {
          ...item,
          isTopLine: isTopLine,
          opcityData: opcityData
        };
      });
      this.availableRoomsCount = this.availableRooms.length;
      this.orgAvailableRooms = JSON.parse(JSON.stringify(this.availableRooms));
    },
    onGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
          },
        },
        node,
      } = args;
      if (this.isModifyMode) {
        const allowedEditColumns = this.createAvailableRoomProps.columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field)
          ?.concat(["usefulRoomCnt", "blockRoomCnt", "webOpenRoomCnt"]);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    async onGridHeaderClicked(column) {
      const gridRefs = this.$refs.createAvailableRoomGrid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];

      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    onGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
          allowEditing,
        },
        data,
      } = args;
      if (allowEditing) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if((field === "bsnDate"|| field === "dwCode" || field === "roomBsnCode")&& !data.opcityData) {
        cell.innerText="";
      }
      if(field === 'dwCode'){
        cell.style.color = commonCodesGetColorValue("DW_CODE", data.dwCode);
      } else if(field === 'roomBsnCode'){
        cell.style.color = commonCodesGetColorValue("ROOM_BSN_CODE", data.roomBsnCode);
      }
      if (["usefulRoomCnt","blockRoomCnt","webOpenRoomCnt","outofRoomCnt","houseRoomCnt","resveCount","chkinCount","usefulRoomCntAll","webOpenRoomCntAll"].includes(field) && data[field] === 0) {
        cell.innerText = "-";
      }

      if (data["isTopLine"]) {
        cell.style.borderTop = '1px solid #000';
      }

      if (this.isModifyMode) {
        const allowedEditColumns = this.createAvailableRoomProps.columns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field)
          ?.concat(["usefulRoomCnt", "blockRoomCnt", "webOpenRoomCnt"]);

        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.createAvailableRoomProps.selectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {mode: 'Both', type: 'Multiple'};
      this.$refs.createAvailableRoomGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.createAvailableRoomGrid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    async onGridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const gridRefs = this.$refs.createAvailableRoomGrid;
      const {
        cellIndex: {
          cellIndex
        },
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        gridRefs?.refresh();
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.createAvailableRoomProps.columns
        ?.forEach(column => {
          columns.push(column);
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          }
        });
      const allowedEditColumns = columns
        ?.filter(item => item.allowEditing)
        ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    async onGridCellSaved(args) {
      const {
        value,
        previousValue,
        rowData,
        cell: {
          parentNode: {
            rowIndex
          },
        },
        column: {
          field,
        },
      } = args;

      const batchCurrentViewRecords = this.$refs.createAvailableRoomGrid.getCurrentViewRecords();

      if (field === "usefulRoomCnt") {
        const usefulRoomCntAll = batchCurrentViewRecords.find(record => record._rid === rowData._rid)?.usefulRoomCntAll || 0;
        this.$refs.createAvailableRoomGrid.setCellValue(rowData["_rid"], "usefulRoomCntAll", usefulRoomCntAll + value - previousValue);
      } else if (field === "blockRoomCnt") {
        const roomCount = rowData.usefulRoomCnt || 0;
        const webOpenRoomCnt = rowData.webOpenRoomCnt || 0;
        if (roomCount < (value + webOpenRoomCnt)) {
          await this.alert("가용 객실수보다 블럭(웹오픈) 객실수가 클 수 없습니다");
          this.$refs.createAvailableRoomGrid.updateCell(rowIndex, field, previousValue);
          return;
        }
        const usefulRoomCntAll = batchCurrentViewRecords.find(record => record._rid === rowData._rid)?.usefulRoomCntAll || 0;
        this.$refs.createAvailableRoomGrid.setCellValue(rowData["_rid"], "usefulRoomCntAll", usefulRoomCntAll - value + previousValue);
      } else if (field === "webOpenRoomCnt") {
        const roomCount = rowData.usefulRoomCnt || 0;
        const blockRoomCnt = rowData.blockRoomCnt || 0;
        if (roomCount < (value + blockRoomCnt)) {
          await this.alert("가용 객실수보다 블럭(웹오픈) 객실수가 클 수 없습니다");
          this.$refs.createAvailableRoomGrid.updateCell(rowIndex, field, previousValue);
          return;
        }
        const findRecord = batchCurrentViewRecords.find(record => record._rid === rowData._rid);
        const webOpenRoomCntAll
        // 웹오픈(사용가능) : 웹오픈(사용가능)보다 전체(사용가능)이 많으면 웹오픈(사용가능), 아니면 전체(사용가능) 데이터.
        = findRecord.usefulRoomCntAll >= (value - findRecord.resveChannelWebRoomCnt) ?
          (value - findRecord.resveChannelWebRoomCnt) :
          findRecord.usefulRoomCntAll;
        this.$refs.createAvailableRoomGrid.setCellValue(rowData["_rid"], "webOpenRoomCntAll", webOpenRoomCntAll);
      }
    },
    async onGridActionBegin() {
      // console.log('onGridActionBegin.args.===>', args);
      // const {
      //   requestType
      // } = args;
      //
      // if (requestType === "paging") {
      //   const batchCurrentViewRecords = this.$refs.createAvailableRoomGrid.getCurrentViewRecords();
      //   console.log('orgAvailableRooms.===>', this.orgAvailableRooms);
      //   console.log('batchCurrentViewRecords.===>', batchCurrentViewRecords);
      //
      //   if (
      //     JSON.stringify(this.orgAvailableRooms.map(item => ({
      //       ...item,
      //       _rid: null,
      //     }))) !== JSON.stringify(batchCurrentViewRecords.map(item => ({
      //       ...item,
      //       _rid: null,
      //     })))) {
      //     args.cancel = true;
      //
      //     await this.$nextTick();
      //
      //     this.$refs.createAvailableRoomGrid.hideSpinner();
      //
      //     if (!(await this.confirm("수정된 데이터가 있습니다. 페이지 이동하시겠습니까?"))) {
      //       return;
      //     }
      //
      //     args.cancel = false;
      //   }
      // }
    },
    onGridActionComplete(args) {
      const {
        requestType,
        action
      } = args;

      if (requestType === "filtering") {
        if (action === "clearFilter") {
          this.availableRoomsCount = this.availableRooms.length;
        } else if ((args?.rows?.length || 0) > 0) {
          const filteredRecords = this.$refs.createAvailableRoomGrid.getFilteredRecords();
          this.availableRoomsCount = filteredRecords.length || 0;
        } else {
          this.availableRoomsCount = 0;
        }
      }
    },
    onExcelButtonClicked() {
      this.$refs.createAvailableRoomGrid.excelExport();
    },
    async onSaveButtonClicked() {
      const {
        changedRecords
      } = this.$refs.createAvailableRoomGrid.patchBatchChanges();

      if (!(changedRecords.length > 0)) {
        this.errorToast("수정된 데이터가 없습니다");
        return;
      }

      const batchCurrentViewRecords = this.$refs.createAvailableRoomGrid.getBatchCurrentViewRecords();

      const saveData = changedRecords.map(changedRecord => {
        const data = batchCurrentViewRecords.find(record => record._rid === changedRecord._rid);
        return {
          ...changedRecord,
          bsnDate: data.bsnDate,
          roomType: data.roomType,
        };
      });

      await GolfErpAPI.patchRoomUsefulSeveral(saveData);

      this.infoToast("저장되었습니다");

      this.onViewButtonClicked();
    },
    createAvailableRoomButtonClicked() {
      this.isCreateAvailableRoomPopupOpen = true;

      this.$nextTick(() => {
        this.$refs.createAvailableRoomPopup.showPopup({
          bsnDateFrom: this.searchOptions.bsnDateFrom,
          bsnDateTo: this.searchOptions.bsnDateTo,
          roomBsnCode: this.searchOptions.roomBsnCode,
        });
      });
    },
    createAvailableRoomPopupClosed(isSave) {
      this.isCreateAvailableRoomPopupOpen = false;

      if (isSave) {
        this.onViewButtonClicked();
      }
    }
  }
};
</script>

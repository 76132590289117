<template>
  <div>
    <ejs-dialog
        ref="createAvailableRoomPopup"
        :header="`가용 객실 생성/삭제`"
        width="600"
        height="500"
        :animationSettings="{ effect: 'None' }"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :enableResize="true"
        :close="closePopup"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">생성일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          v-model="searchDateInputDateRange"
                          type="lookupDetail-condition"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">영업구분</div>
                    <ul class="content">
                      <li class="item select">
                        <ejs-dropdownlist
                          cssClass="lookup-condition-dropdown"
                          v-model="searchOptions.roomBsnCode"
                          :dataSource="searchOptions.roomBsnCodeOptions"
                          :fields="commonCodeFields"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">가용 객실 생성/삭제</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-check">
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              v-model="isAllWebOpen"
                              @change="onAllWebOpenChanged"
                            />
                            <i/>
                            <div class="label">웹 전체 오픈</div>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                          ref="grid"
                          v-bind="gridProps"
                          :dataSource="createAvailableRoom"
                          @queryCellInfo="onGridQueryCellInfo"
                          @cellSaved="onGridCellSaved"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <div class="lookup-condition">
            <ul class="button">
              <li class="delete">
                <erp-button
                    button-div="DELETE"
                    :ignore="isPopupOpened"
                    :is-shortcut-button="true"
                    @click.native="onDeleteButtonClicked"
                >
                  삭제
                </erp-button>
              </li>
            </ul>
          </div>
          <ul class="button">
            <li class="create keyColor">
              <erp-button
                  button-div="SAVE"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                  v-on:click.native="onSaveButtonClicked"
              >
                생성
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseButtonClicked">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import InputDateRange from "@/components/common/datetime/InputDateRange";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Edit, ExcelExport, ForeignKey, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'CreateAvailableRoomPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    InputDateRange,
    ErpButton,
  },
  data() {
    return {
      commonCodeFields: { text: "comName", value: "comCode" },
      searchOptions: {
        bsnDateFrom: null,
        bsnDateTo: null,
        roomBsnCode: null,
        roomBsnCodeOptions: [],
      },
      createAvailableRoom: [],
      isAllWebOpen: false,
      commonCodesByRoomType: commonCodesGetCommonCode("ROOM_TYPE", true),
      isSave: false,
    };
  },
  created() {
    this.searchOptions.roomBsnCodeOptions = commonCodesGetCommonCode(
      "ROOM_BSN_CODE",
      true
    );

    if (
      this.searchOptions.roomBsnCodeOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.roomBsnCodeOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
  },
  mounted() {},
  computed: {
    isPopupOpened() {
      return false;
    },
    searchDateInputDateRange: {
      get: function () {
        return {
          from: this.searchOptions.bsnDateFrom,
          to: this.searchOptions.bsnDateTo
        };
      },
      set: function (dateRange) {
        this.searchOptions.bsnDateFrom = dateRange.from;
        this.searchOptions.bsnDateTo = dateRange.to;
      }
    },
    gridProps() {
      return {
        provides: [Edit, ForeignKey, Selection, Resize, ExcelExport],
        allowFiltering: false,
        allowSorting: false,
        columns: [
          {
            field: "isSelect",
            headerText: "",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            isSelectAllColumn: true,
            minWidth: 16,
            width: 40,
            textAlign: "center",
          },
          {
            field: "roomType",
            headerText: "객실타입",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_TYPE",
            allowEditing: false,
            isPrimaryKey: true,
          },
          {
            field: "roomCount",
            headerText: "객실수",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
            allowEditing: false,
          },
          {
            field: "usefulRoomCnt",
            headerText: "가용",
            type: "number",
            isNumericType: true,
            inputNumberProperty: { maxLength: 4 },
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "blockRoomCnt",
            headerText: "블럭",
            type: "number",
            isNumericType: true,
            inputNumberProperty: { maxLength: 4 },
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "webOpenRoomCnt",
            headerText: "웹오픈",
            type: "number",
            isNumericType: true,
            inputNumberProperty: { maxLength: 4 },
            minWidth: 16,
            width: 90,
            textAlign: "right",
          }
        ],
      };
    }
  },
  methods: {
    showPopup(args) {
      this.searchOptions.bsnDateFrom = args.bsnDateFrom;
      this.searchOptions.bsnDateTo = args.bsnDateTo;
      this.searchOptions.roomBsnCode = args.roomBsnCode;

      this.fetch();
    },
    closePopup() {
      this.$emit("popupClosed", this.isSave);
    },
    onCloseButtonClicked() {
      this.$refs.createAvailableRoomPopup.hide();
    },
    async fetch() {
      const data = await GolfErpAPI.getRoomNumber({});

      this.createAvailableRoom = this.commonCodesByRoomType.map(roomType => {
        return {
          roomType: roomType.comCode,
          roomCount: data.filter(item => item.roomType === roomType.comCode && !item.virtlRoomFlag && item.useFlag).length,
          usefulRoomCnt: 0,
          blockRoomCnt: 0,
          webOpenRoomCnt: 0,
          isSelect: false,
        };
      });
    },
    async onSaveButtonClicked() {
      const batchCurrentViewRecords = this.$refs.grid.getBatchCurrentViewRecords().filter(item => item.isSelect);

      if (!(batchCurrentViewRecords.length > 0)) {
        this.errorToast("생성할 데이터를 선택하여 주시기 바랍니다");
        return;
      }

      const roomTypes = batchCurrentViewRecords.map(item => item.roomType);

      const availableRooms = await GolfErpAPI.getAvailableRoomsByRaw({
        bsnDateFrom: this.searchOptions.bsnDateFrom,
        bsnDateTo: this.searchOptions.bsnDateTo,
        roomBsnCode: this.searchOptions.roomBsnCode === "ALL" ? null : this.searchOptions.roomBsnCode,
        roomTypes: roomTypes.length === this.commonCodesByRoomType.length ? null : roomTypes,
      });

      let isDelete = false;
      if (availableRooms.length > 0) {
        if (!(await this.confirm(
          this.searchOptions.bsnDateFrom + " 부터 " + this.searchOptions.bsnDateTo + " 까지<br>이미 생성된 가용 객실 데이터가 있습니다.<br><br>삭제하시고 다시 생성하시겠습니까?"
        ))) {
          return;
        }

        isDelete = true;
      } else {
        if (!(await this.confirm(
          this.searchOptions.bsnDateFrom + "부터 " + this.searchOptions.bsnDateTo + "까지 가용객실 정보를 생성하시겠습니까?"
        ))) {
          return;
        }
      }

      await GolfErpAPI.createAvailableRooms({
        bsnDateFrom: this.searchOptions.bsnDateFrom,
        bsnDateTo: this.searchOptions.bsnDateTo,
        roomBsnCode: this.searchOptions.roomBsnCode === "ALL" ? null : this.searchOptions.roomBsnCode,
        roomUsefuls: batchCurrentViewRecords,
        isDelete: isDelete,
      });

      this.infoToast("생성 완료되었습니다");

      this.isSave = true;
    },
    async onDeleteButtonClicked() {
      const batchCurrentViewRecords = this.$refs.grid.getBatchCurrentViewRecords().filter(item => item.isSelect);

      if (!(batchCurrentViewRecords.length > 0)) {
        this.errorToast("삭제할 데이터를 선택하여 주시기 바랍니다");
        return;
      }

      if (!(await this.confirm(
        this.searchOptions.bsnDateFrom + " 부터 " + this.searchOptions.bsnDateTo + " 까지의 가용객실 정보를 삭제하시겠습니까?"
      ))) {
        return;
      }

      await GolfErpAPI.deleteAvailableRooms({
        bsnDateFrom: this.searchOptions.bsnDateFrom,
        bsnDateTo: this.searchOptions.bsnDateTo,
        roomBsnCode: this.searchOptions.roomBsnCode === "ALL" ? null : this.searchOptions.roomBsnCode,
        roomUsefuls: batchCurrentViewRecords,
      });

      this.infoToast("삭제 완료되었습니다");

      this.isSave = true;
    },
    onGridQueryCellInfo(args) {
      const {
        cell,
        column,
        data,
      } = args;

      if (column.allowEditing) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }

      if (["roomCount","usefulRoomCnt","blockRoomCnt","webOpenRoomCnt"].includes(column.field) && data[column.field] === 0) {
        cell.innerText = "-";
      }
    },
    async onGridCellSaved(args) {
      const {
        cell: {
          parentNode: {
            rowIndex
          },
        },
        column: {
          field
        },
        rowData,
        value,
        previousValue,
      } = args;

      if (field === "usefulRoomCnt") {
        const roomCount = rowData.roomCount || 0;
        if (roomCount < value) {
          if (!(await this.confirm("총 객실수보다 가용 객실수가 큽니다. 그대로 입력 하시겠습니까?"))) {
            this.$refs.grid.updateCell(rowIndex, field, previousValue);
          }
        }
      } else if (field === "blockRoomCnt") {
        const roomCount = rowData.usefulRoomCnt || 0;
        const webOpenRoomCnt = rowData.webOpenRoomCnt || 0;
        if (roomCount < (value + webOpenRoomCnt)) {
          await this.alert("가용 객실수보다 블럭(웹오픈) 객실수가 클 수 없습니다");
          this.$refs.grid.updateCell(rowIndex, field, previousValue);
        }
      } else if (field === "webOpenRoomCnt") {
        const roomCount = rowData.usefulRoomCnt || 0;
        const blockRoomCnt = rowData.blockRoomCnt || 0;
        if (roomCount < (value + blockRoomCnt)) {
          await this.alert("가용 객실수보다 블럭(웹오픈) 객실수가 클 수 없습니다");
          this.$refs.grid.updateCell(rowIndex, field, previousValue);
        }
      }
    },
    onAllWebOpenChanged() {
      const batchCurrentViewRecords = this.$refs.grid.getBatchCurrentViewRecords();

      if (this.isAllWebOpen) {
        this.createAvailableRoom = batchCurrentViewRecords.map(record => {
          return {
            ...record,
            webOpenRoomCnt: record.usefulRoomCnt,
          };
        });
      }
    }
  },
};
</script>
